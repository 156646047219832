import React from 'react';
import { bool, object } from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import user_avatar from '../../assets/images/defaultSellerAvatar.webp';

const SellerDetails = ({ seller, isProfile }) => (
  <div className={isProfile && 'container py-3'}>
    {!isProfile && <h5>Seller Description</h5>}
    <div
      className={
        isProfile
          ? 'ml-md-auto mx-auto mx-md-0 col-sm-9 offset-sm-3 border border-primary rounded row justify-content-center py-3'
          : 'row no-gutters'
      }
    >
      <div className={`mr-3 ${isProfile && 'my-auto'}`}>
        {isProfile ? (
          <img
            width={75}
            height={75}
            className="default_seller_avatar rounded-circle"
            src={!seller.avatar ? user_avatar : seller.avatar}
            alt="seller avatar"
          />
        ) : (
          <Link to={`/account/${seller.username}`}>
            <img
              width={70}
              height={70}
              className="default_seller_avatar rounded-circle"
              src={!seller.avatar ? user_avatar : seller.avatar}
              alt="seller avatar"
            />
          </Link>
        )}
      </div>
      <div>
        {isProfile ? (
          <h2>{seller.username}</h2>
        ) : (
          <Link to={`/account/${seller.username}`}>{seller.username}</Link>
        )}
        {seller.address && (
          <div className="text-nowrap">
            <small className="font-weight-bold">
              <span className="text-muted">Ships from:</span>{' '}
              {seller.address.state}, {seller.address.country}
            </small>{' '}
            {seller.address.country === 'CA' && (
              <FontAwesomeIcon
                icon={['fab', 'canadian-maple-leaf']}
                // color='#ff0000'
              />
            )}
          </div>
        )}
        {seller?.created_at && (
          <div className="text-nowrap">
            <small className="font-weight-bold">
              <span className="text-muted">User since:</span>{' '}
              {`${seller.created_at.split(' ')[0].split('-')[1]}/${
                seller.created_at.split(' ')[0].split('-')[0]
              }`}
            </small>{' '}
          </div>
        )}
        <div className="text-nowrap">
          {seller?.seller_account?.review_count > 0 ? (
            <small className="font-weight-bold">
              <span className="text-muted">Positive Feedback:</span>{' '}
              <span>{seller.seller_account.percent_positive}% </span>
              <Link to={`/account/${seller.username}/feedback`}>
                ({seller.seller_account.review_count} reviews)
              </Link>
            </small>
          ) : (
            <small className="font-weight-bold">No reviews</small>
          )}
        </div>
      </div>

      <div className="row ml-1">{seller.desc}</div>
    </div>
  </div>
);

SellerDetails.propTypes = {
  isProfile: bool,
  seller: object,
};

SellerDetails.defaultProps = {
  isProfile: false,
};

export default SellerDetails;
